@font-face {
  font-family: 'NeueHaasGrotesk';
  src: url('/font/Neue-Haas-Grotesk-Display-Pro/NeueHaasDisplayLight.ttf')
    format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'NeueHaasGrotesk';
  src: url('/font/Neue-Haas-Grotesk-Display-Pro/NeueHaasDisplayRoman.ttf')
    format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'NeueHaasGrotesk';
  src: url('/font/Neue-Haas-Grotesk-Display-Pro/NeueHaasDisplayRomanItalic.ttf')
    format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'NeueHaasGrotesk';
  src: url('/font/Neue-Haas-Grotesk-Display-Pro/NHaasGroteskDSPro-65Md.ttf')
    format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'NeueHaasGrotesk';
  src: url('/font/Neue-Haas-Grotesk-Display-Pro/Neue\ Haas\ Grotesk\ Display\ Pro\ 75\ Bold.ttf')
    format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'NeueHaasGrotesk';
  src: url('/font/Neue-Haas-Grotesk-Display-Pro/NHaasGroteskDSPro-95Blk.woff.ttf')
    format('truetype');
  font-weight: 900;
  font-style: normal;
}
