@import url(./utils/font.scss);

@tailwind base;
@tailwind components;
@tailwind utilities;

*:not(mat-icon) {
  /** Set the default font-family and the default font-weight **/
  @apply font-NeueHaasGrotesk font-normal;
  font-size: 14px;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  //   @apply bg-neutral-200;
}

.input {
  // TODO: Will refactor this to use mat-outline-form-field
  @apply bg-white border border-neutral-300 border-solid shadow-xs rounded-3xl py-3 px-[10px] outline-none w-full flex text-center;
}

@import url(./theme/theming.scss);

/*********************** UVA FORM FIELDS *******************/
.uva-form-field {
  @apply bg-white border border-solid border-neutral-300 rounded-2xl relative flex flex-col px-3 py-2.5;

  input,
  textarea {
    @apply outline-none w-full;
  }

  &:hover,
  &:focus-within {
    @apply border border-primary-300;
  }

  &:focus-within {
    box-shadow: 0 0 0 4px var(--color-primary-100);
  }

  &:has(input.ng-invalid.ng-touched),
  &:has(textarea.ng-invalid.ng-touched) {
    border-color: var(--color-destructive-300);
    // box-shadow: 0 0 0 4px var(--color-destructive-100);
  }

  &:has(input.ng-invalid.ng-touched):focus-within,
  &:has(textarea.ng-invalid.ng-touched):focus-within {
    // border-color: var(--color-destructive-300);
    box-shadow: 0 0 0 4px var(--color-destructive-100);
  }
}

.uva-error {
  @apply w-full mt-1 opacity-100 text-destructive-500 block;
  animation: slidedown 0.2s ease-out;
}

@keyframes slidedown {
  from {
    @apply mt-0 opacity-0;
  }

  to {
    @apply mt-1 opacity-100;
  }
}

.uva-hint {
  @apply block w-full mt-1;
}

.public-view-label::after {
  content: '*';
  @apply text-destructive-500;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 27px;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 27px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
