html {
  --mat-form-field-container-height: 40px;
  --mat-form-field-filled-label-display: block;
  --mat-form-field-container-vertical-padding: 10px;
  // --mat-form-field-filled-with-label-container-padding-top: 8px;
  // --mat-form-field-filled-with-label-container-padding-bottom: 8px;

  /* outline size */
  --mdc-outlined-text-field-outline-width: 1px;
  --mdc-outlined-text-field-focus-outline-width: 1px;

  /* border-radius */
  --mdc-outlined-text-field-container-shape: theme(size.4);

  /* primary / default color */
  --mdc-outlined-text-field-focus-outline-color: theme(colors.primary.300);
  --mdc-outlined-text-field-outline-color: theme(colors.neutral.300);
  --mdc-outlined-text-field-hover-outline-color: theme(colors.primary.300);

  --mdc-outlined-text-field-error-focus-outline-color: theme(
    colors.destructive.300
  );
  --mdc-outlined-text-field-error-hover-outline-color: theme(
    colors.destructive.300
  );
  --mdc-outlined-text-field-error-outline-color: theme(colors.destructive.300);
  --mdc-outlined-text-field-input-text-placeholder-color: theme(
    colors.neutral.500
  );

  --mdc-filled-text-field-container-color: theme(colors.transparent);
  --mdc-filled-text-field-input-text-placeholder-color: theme(
    colors.neutral.300
  );

  --mdc-filled-text-field-input-text-color: theme(colors.neutral.900);

  mat-form-field {
    font-weight: 900 !important;
  }

  // Bolden the text in the mat-form-field with filled appearance input control
  .mdc-text-field--filled .mat-mdc-form-field-input-control {
    font-weight: 600 !important;
  }

  --mdc-filled-text-field-error-active-indicator-color: theme(
    colors.destructive.500
  );
  --mat-form-field-error-text-color: theme(colors.destructive.500);
  --mat-form-field-state-layer-color: theme(
    colors.transparent
  ); // For darkened tint of the form-field when hovered on
  --mdc-filled-text-field-focus-active-indicator-color: theme(
    colors.primary.500
  );
  --mdc-filled-text-field-active-indicator-color: theme(colors.neutral.300);

  --mdc-filled-text-field-focus-active-indicator-height: 1px;
  --mdc-filled-text-field-error-active-indicator-color: theme(
    colors.destructive.500
  );
  --mdc-filled-text-field-error-focus-active-indicator-color: theme(
    colors.destructive.500
  );
  --mdc-filled-text-field-error-hover-active-indicator-color: theme(
    colors.destructive.500
  );

  mat-form-field {
    &.remove-left-padding .mdc-text-field:has(input) {
      padding-left: 0 !important;
    }

    &.mat-form-field-appearance-outline {
      @apply rounded-2xl;

      &:focus-within {
        box-shadow: 0 0 0 4px var(--color-primary-100) !important;
      }

      &.mat-form-field-invalid.ng-touched:focus-within {
        box-shadow: 0 0 0 4px var(--color-destructive-100) !important;
      }
    }
  }

  .mat-mdc-option {
    @apply font-NeueHaasGrotesk;
  }

  .mat-mdc-form-field-error-wrapper {
    padding-left: 0 !important;
  }

  /***** MAT SPINNER *******/
  --mdc-circular-progress-active-indicator-color: theme(colors.primary.500);

  /****** SELECT ******/
  /* default/primary color */
  --mat-select-panel-background-color: theme(colors.white);
  // --mat-select-enabled-trigger-text-color: rgba(255, 255, 255, .87);
  // --mat-select-disabled-trigger-text-color: rgba(255, 255, 255, .38);
  --mat-select-placeholder-text-color: theme(colors.neutral.300);
  --mat-select-enabled-arrow-color: theme(colors.neutral.500);
  // --mat-select-disabled-arrow-color: rgba(255, 255, 255, .38);
  // --mat-select-focused-arrow-color: rgba(156, 39, 176, .87);
  // --mat-select-invalid-arrow-color: rgba(244, 67, 54, .87)
  --mat-select-container-elevation-shadow: theme(
    boxShadow.xl
  ); // Shadow of panel

  /* Options */
  // --mat-option-label-text-size: 3rem; // For form options
  --mat-option-selected-state-layer-color: theme(colors.neutral.200);

  /******************* SLIDE TOGGLE ********************/
  /* size */
  --mdc-switch-handle-shape: 100%;
  --mdc-switch-selected-icon-size: 0px;
  --mdc-switch-track-height: 24px;
  --mdc-switch-track-width: 39px; // 48px;
  --mdc-switch-unselected-icon-size: 0px;
  --mdc-switch-state-layer-size: 21px;

  --mat-switch-selected-with-icon-handle-horizontal-margin: 2.5px 18px;
  --mat-switch-unselected-with-icon-handle-horizontal-margin: 2.5px 3px;

  --mat-switch-selected-pressed-handle-horizontal-margin: 2.5px 18px;
  --mat-switch-unselected-pressed-handle-horizontal-margin: 2.5px 3px;

  --mdc-switch-selected-pressed-state-layer-opacity: 0;
  --mdc-switch-unselected-pressed-state-layer-opacity: 0;
  --mat-switch-pressed-handle-size: 21px;

  --mdc-switch-unselected-handle-color: theme(colors.white);
  --mdc-switch-unselected-hover-handle-color: theme(colors.white);

  // Hide the arrow to the right of the mat-select to be able to use a custom icon
  .mat-mdc-select-arrow-wrapper {
    @apply hidden;
  }

  /* Change the font weight of the selected option in the mat-select */
  .mat-select-bolden-display-text {
    .mat-mdc-select-min-line {
      font-weight: 600;
    }
  }

  // Change the suffix icon color in the mat-form-field with mat-select
  mat-form-field:has(mat-select) .mat-mdc-form-field-icon-suffix mat-icon {
    @apply text-neutral-500;
  }

  /*/****************** DATE PICKER *******************/
  /* default/primary color */
  --mat-datepicker-calendar-date-preview-state-outline-color: theme(
    colors.primary.500
  );
  --mat-datepicker-calendar-date-selected-state-text-color: theme(colors.white);
  --mat-datepicker-calendar-date-selected-state-background-color: theme(
    colors.primary.500
  );
  --mat-datepicker-calendar-date-focus-state-background-color: theme(
    colors.white
  );
  --mat-datepicker-calendar-date-hover-state-background-color: theme(
    colors.primary.50
  );
  --mat-datepicker-calendar-navigation-button-icon-color: theme(
    colors.neutral.900
  );
  --mat-datepicker-calendar-header-text-color: theme(colors.neutral.900);
  --mat-datepicker-calendar-date-text-color: theme(colors.neutral.900);
  --mat-datepicker-calendar-container-background-color: theme(colors.white);
  --mat-datepicker-calendar-date-in-range-state-background-color: theme(
    colors.primary.50
  );
  --mat-datepicker-calendar-body-label-text-color: theme(colors.neutral.900);
  --mat-datepicker-calendar-header-divider-color: theme(colors.neutral.200);
  --mat-datepicker-calendar-date-outline-color: theme(colors.transparent);
  --mat-datepicker-calendar-date-today-outline-color: theme(colors.transparent);
  --mat-datepicker-calendar-container-shape: 6px;
  --mat-datepicker-calendar-container-elevation-shadow: theme(boxShadow.xl);

  .mat-calendar-body-today {
    @apply text-destructive-500; // Color of today's date text
  }

  .mat-datepicker-content .mat-calendar {
    height: auto;
  }

  .mat-datepicker-actions {
    justify-content: space-between;

    @apply border-t border-neutral-200 border-solid py-5 mx-4 px-0;

    button {
      width: 100%;
    }
  }

  /****************** BUTTON ******************/
  --mat-filled-button-horizontal-padding: 20px;
  --mdc-filled-button-container-shape: 16px;
  --mdc-filled-button-container-color: theme(colors.primary.500);
  --mat-outlined-button-horizontal-padding: 20px;
  --mdc-outlined-button-container-shape: 16px;
  --mdc-outlined-button-label-text-color: theme(colors.neutral.700);
  --mdc-outlined-button-outline-color: theme(colors.neutral.300);

  .mdc-button {
    .mdc-button__label {
      font-weight: 600 !important;
    }
  }

  /** Add this class to buttons to apply the styles in this class */
  .small {
    --mdc-filled-button-container-height: 36px;
  }

  /****************** CHECKBOX ****************/

  /* default/accent color */
  --mdc-checkbox-unselected-icon-color: theme(colors.neutral.300);

  .mat-accent.mat-mdc-checkbox {
    --mdc-checkbox-selected-checkmark-color: theme(colors.white);
    --mdc-checkbox-selected-icon-color: theme(colors.primary.500);
    --mdc-checkbox-selected-focus-icon-color: theme(colors.primary.500);
    --mdc-checkbox-selected-hover-icon-color: theme(colors.primary.500);
    --mdc-checkbox-selected-focus-state-layer-color: theme(colors.primary.500);
    --mdc-checkbox-selected-hover-state-layer-color: theme(colors.primary.500);
    --mdc-checkbox-selected-pressed-state-layer-color: theme(
      colors.primary.500
    );
    --mdc-checkbox-unselected-hover-icon-color: theme(colors.neutral.400);
    --mdc-checkbox-unselected-focus-icon-color: theme(colors.neutral.300);
    --mdc-checkbox-unselected-focus-state-layer-color: theme(colors.white);
    --mdc-checkbox-unselected-hover-state-layer-color: theme(
      colors.primary.500
    );
    --mdc-checkbox-unselected-pressed-state-layer-color: theme(
      colors.primary.500
    );
    --mdc-checkbox-disabled-selected-checkmark-color: theme(colors.white);
    --mdc-checkbox-disabled-selected-icon-color: theme(colors.primary.600);
  }

  .mdc-checkbox__background {
    border-radius: 4px;
  }

  /****************** RADIO BUTTON ****************/
  --mdc-radio-unselected-icon-color: theme(colors.neutral.300);
  --mdc-radio-unselected-hover-icon-color: theme(colors.neutral.300);

  .mat-accent.mat-mdc-radio-button {
    --mdc-radio-selected-focus-icon-color: theme(colors.primary.500);
    --mdc-radio-selected-hover-icon-color: theme(colors.primary.600);
    --mdc-radio-selected-icon-color: theme(colors.primary.500);
    --mdc-radio-selected-pressed-icon-color: theme(colors.primary.500);
    --mat-radio-checked-ripple-color: theme(colors.primary.500);
  }

  /************* TOOLBAR *****************/
  --mat-toolbar-standard-height: 88px !important;

  /************ TABS ********************/
  .mat-mdc-tab-group,
  .mat-mdc-tab-nav-bar {
    --mdc-tab-indicator-active-indicator-color: theme(colors.primary.500);
    --mat-tab-header-inactive-label-text-color: theme(colors.neutral.700);
    --mat-tab-header-active-label-text-color: theme(colors.primary.500);
    --mat-tab-header-active-ripple-color: theme(colors.primary.500);
    --mat-tab-header-inactive-ripple-color: theme(colors.neutral.700);
    --mat-tab-header-inactive-focus-label-text-color: theme(colors.neutral.700);
    --mat-tab-header-inactive-hover-label-text-color: theme(colors.neutral.700);
    --mat-tab-header-active-focus-label-text-color: theme(colors.primary.500);
    --mat-tab-header-active-hover-label-text-color: theme(colors.primary.500);
    --mat-tab-header-active-focus-indicator-color: theme(colors.primary.500);
    --mat-tab-header-active-hover-indicator-color: theme(colors.primary.500);
    --mdc-tab-indicator-active-indicator-height: 1px;
  }

  --mat-tab-header-divider-color: theme(colors.transparent);
  --mat-tab-header-divider-height: 0;

  .mat-mdc-tab-header .mat-mdc-tab-labels .mat-mdc-tab .mdc-tab__text-label {
    font-weight: 600 !important;
    // --mat-tab-header-label-text-weight: theme(fontWeight.bold) !important;
  }

  /************* MENU ******************/
  --mat-menu-container-color: theme(colors.white);
  --mat-menu-container-shape: theme(borderRadius.xl);
  --mat-menu-item-hover-state-layer-color: theme(colors.neutral.200);

  .mat-mdc-menu-item {
    min-height: 36px;
  }

  .mat-mdc-menu-panel {
    box-shadow: var(--box-shadow-xl) !important;
    @apply border border-solid border-neutral-200;
  }

  .mat-mdc-menu-content {
    @apply py-0;
  }

  /************* DIALOG ******************/
  --mdc-dialog-container-shape: 6px;
  --mdc-dialog-container-color: theme(colors.white);

  /* Table */
  --mat-table-header-headline-color: theme(colors.black);
  --mat-table-header-container-height: 56px;
  --mat-table-header-headline-size: 14px;
  --mat-table-header-headline-weight: theme(fontWeight.semibold);
  --mat-table-row-item-outline-color: theme(colors.neutral.200);

  /* Table Header bacground */
  .table-header-bg-color.mat-mdc-table thead {
    @apply bg-primary-50;
  }
}

/**************** SELECT DROPDOWN PANEL **************/
div.mat-mdc-select-panel {
  border-radius: 1rem !important;
  margin-top: 0.25rem !important;
}

.mdc-switch__handle::before,
.mdc-switch__handle::after {
  width: 80% !important;
  height: 80% !important;
}

.mdc-switch__track::before {
  border: none !important;
}

.mat-mdc-slide-toggle {
  --mdc-switch-selected-hover-track-color: theme(colors.primary.500) !important;
  --mdc-switch-selected-track-color: theme(colors.primary.500) !important;
  --mdc-switch-unselected-track-color: theme(colors.neutral.200) !important;
  --mdc-switch-unselected-focus-handle-color: theme(
    colors.neutral.400
  ) !important;
  --mdc-switch-unselected-pressed-handle-color: theme(
    colors.neutral.400
  ) !important;
  --mdc-switch-selected-focus-track-color: theme(colors.primary.500) !important;
  --mdc-switch-selected-pressed-handle-color: theme(colors.white) !important;
  --mdc-switch-selected-pressed-track-color: theme(
    colors.primary.500
  ) !important;
}
